import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { DateTime } from 'luxon';
import { TreeNode } from 'primeng/api';
import { catchError, Observable, of, tap } from 'rxjs';

import { Project } from '../interfaces/project';
import { PublicMeeting, PublicMeetingResponse, ZoomMeeting, ZoomMeetingLink } from '../interfaces/zoom';

@Injectable({
  providedIn: 'root'
})
export class ProjectInfrastructureService {
  public meetings: WritableSignal<PublicMeeting[]> = signal([]);
  public pastMeetings$: Observable<PublicMeetingResponse>;
  public pastMeetings: WritableSignal<PublicMeeting[]> = signal([]);
  public project: WritableSignal<Partial<Project>> = signal({} as Project);
  public date: WritableSignal<Date> = signal(new Date());
  public projects: WritableSignal<TreeNode[]> = signal([]);
  public selectedProjects: WritableSignal<TreeNode[]> = signal([]);
  public startDate: WritableSignal<Date> = signal(new Date());
  public endDate: WritableSignal<Date> = signal(new Date());
  public slug: WritableSignal<string> = signal('');

  private publicProjectServicesAPI = environment.apiURL + '/public/project-infrastructure-services';
  private projectServicesAPI = environment.apiURL + '/project-infrastructure-services';
  private itxService = environment.apiV2URL + '/itx-services';
  private urls = {
    zoom: `${this.publicProjectServicesAPI}/zoom`,
    meetings: `${this.projectServicesAPI}/zoom/meetings`,
    publicMeetings: `${this.publicProjectServicesAPI}/zoom/meetings`
  };

  private httpClient: HttpClient = inject(HttpClient);
  private router: Router = inject(Router);

  public constructor() {}

  public getMeeting(id: string, params?: HttpParams): Observable<ZoomMeeting> {
    return this.httpClient.get<ZoomMeeting>(`${this.urls.publicMeetings}/${id}`, { params });
  }

  public getMeetingLink(id: string, params?: HttpParams): Observable<ZoomMeetingLink> {
    return this.httpClient.get<ZoomMeetingLink>(`${this.urls.meetings}/${id}/join_link`, { params });
  }

  public getPublicMeetingLink(id: string, params?: HttpParams): Observable<ZoomMeetingLink> {
    return this.httpClient.get<ZoomMeetingLink>(`${this.urls.publicMeetings}/${id}/join_link`, { params });
  }

  public postRequestInvite(id: string, data: { name: string; email: string }, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.httpClient.post<any>(`${this.urls.publicMeetings}/${id}/request-invite`, data, { params, observe: 'response' });
  }

  public getProjectMeetings(slug: string): Observable<ZoomMeeting[]> {
    return this.httpClient.get<ZoomMeeting[]>(`${this.urls.zoom}/${slug}/meetings`);
  }

  public getPublicPastMeetings(slug: string, start: Date, end: Date): Observable<PublicMeetingResponse> {
    const params = new HttpParams({
      fromObject: {
        start_date: DateTime.fromJSDate(start).toISODate() as string,
        end_date: DateTime.fromJSDate(end).toISODate() as string
      }
    });

    return this.httpClient.get<PublicMeetingResponse>(`${this.itxService}/public/meetings/${slug}/past`, { params }).pipe(
      tap((res) => {
        this.pastMeetings.set(res.meetings);
      })
    );
  }

  public getPublicMeetings(slug: string, selectedProjects: string[] = []): Observable<PublicMeetingResponse> {
    this.slug.set(slug);

    // Reset meetings
    this.meetings.set([]);
    this.project.set({} as Project);
    this.projects.set([]);

    const params = new HttpParams({
      fromObject: {
        view: 'pcc'
      }
    });

    return this.httpClient.get<PublicMeetingResponse>(`${this.itxService}/public/meetings/${slug}`, { params }).pipe(
      catchError((error) => {
        console.error(error);
        this.router.navigate(['/meetings/project-not-found']);
        return of({ meetings: [], project: {} as Project });
      }),
      tap((res) => {
        this.meetings.set(res.meetings);
        this.project.set(res.project);
        this.projects.set([
          {
            key: res.project.ID,
            label: res.project.Name,
            data: res.project,
            expanded: true,
            leaf: res.project.Projects && res.project.Projects.length > 0,
            children: this.mapTreeNode(res.project.Projects || [], true),
            selectable: true
          }
        ]);

        this.selectAllProjects(res.project);

        if (selectedProjects.length > 0) {
          this.selectedProjects.set(this.selectedProjects().filter((project) => selectedProjects.includes(project.data.Slug)));
        }
      })
    );
  }

  public setDate(date: Date) {
    this.date.set(date);
  }

  public selectAllProjects(project: Project | Partial<Project>): void {
    this.selectedProjects.set([
      {
        key: project.ID,
        label: project.Name,
        data: project,
        expanded: true,
        leaf: project.Projects && project.Projects.length > 0,
        children: this.mapTreeNode(project.Projects || [], true),
        selectable: true
      },
      ...this.mapTreeNode(project.Projects || [])
    ]);
  }

  private mapTreeNode(projects: Project[], expanded: boolean = false): TreeNode[] {
    return projects.map((project) => {
      return {
        key: project.ID,
        label: project.Name,
        data: project,
        expanded: expanded,
        leaf: project.Projects && project.Projects.length > 0,
        children: this.mapTreeNode(project.Projects || []),
        selectable: true
      };
    });
  }
}
